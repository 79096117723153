
/**
 * @name: client
 * @author: Gzm
 * @date: 2023-07-26 15:39
 * @description：client
 * @update: 2023-07-26 15:39
 */
import {Component, Vue} from "vue-property-decorator";
import {ISystemAccount, ISystemAccountQuery} from "@/apis/systemManage/account/types";
import {
  systemAccountQueryApi,
} from "@/apis/systemManage/account";

import {ICrudOption} from "@/types/m-ui-crud";
import config from "@/config";
import {IUserQuery} from "@/apis/sale/settings/types";
import {userExportApi, userQueryApi} from "@/apis/sale/settings";
import {exportFile} from "@/utils/common";


@Component({})
export default class UserIndex extends Vue {


  queryForm: IUserQuery = {
    page: 1,
    limit: 10
  }
  total: number = 0

  tableData: ISystemAccount[] = []

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: ICrudOption = {
    searchBox: true,
    menu: false,
    editBtn: true,
    delBtn: true,

    column: [
      {
        label: "客户",
        prop: "antiStop",
        search: true,
        hide: true,
        addHide: true,
        editHide: true,
        viewHide: true,
        placeholder: "输入昵称/手机号模糊搜索"
      },
      {
        label: "昵称",
        prop: "nickName",
        align: 'center',
      },
      {
        label: "头像",
        prop: "avatarUrl",
        type: 'image',
        align: 'center',
        imgPrefix: config.downloadUrl,
      },
      {
        label: "手机号",
        prop: "phone",
        align: 'center',
      },
      {
        label: "购买次数",
        prop: "payQty",
        align: 'center',
      },
    ]
  }

  modelForm = {

  }

  /**
   * 导出excel
   */
  exportExcel() {
    userExportApi(this.queryForm).then(e => {
      exportFile(e, "客户信息.xlsx")
    })
  }

  getList() {
    console.log(this.queryForm)
    userQueryApi(this.queryForm).then(e => {
      //@ts-ignore
      this.tableData = e.list;
      this.total = e.total
    })
  }

  created() {
    this.getList();
  }
}
